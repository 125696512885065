import React from "react";
import toast from "react-hot-toast";

export const ZipCodeSearch = ({
  setZipCode,
  isThereZipCode,
  zipCode,
  disabled,
  getCoordsFromBrowser,
  setUnits,
  units,
}) => {
  const browserLocation = () => {
    setZipCode("");
    if (disabled) {
      toast.error("Browser location is disabled");
    } else {
      getCoordsFromBrowser();
    }
  };

  return (
    <div className="zipcode-search">
      <input
        placeholder="Zip Code"
        className="zipcode-input zipcode-inputs"
        onChange={(e) => setZipCode(e.target.value)}
        value={zipCode}
      />
      <button
        className="zipcode-button zipcode-inputs"
        onClick={() => isThereZipCode(zipCode)}
      >
        Search
      </button>
      <button
        className="zipcode-button zipcode-inputs"
        onClick={() => browserLocation(zipCode)}
      >
        Current location
      </button>
      <button
        className="units-button zipcode-inputs"
        label="Switch Units"
        onClick={() => setUnits(units === "imperial" ? "metric" : "imperial")}
      >
        Switch Units
      </button>
    </div>
  );
};
