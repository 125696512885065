import React from "react";

export const ZipCodeSearchMini = ({
  setZipCode,
  isThereZipCode,
  zipCode,
}) => {
  return (
    <div className="zipcode-search-mini">
      <input
        placeholder="Zip Code"
        className="zipcode-input zipcode-inputs"
        onChange={(e) => setZipCode(e.target.value)}
        value={zipCode}
      />
      <button
        className="zipcode-button zipcode-inputs"
        onClick={() => isThereZipCode(zipCode)}
      >
        Search
      </button>
    </div>
  );
};
