import React from "react";
import Modal from "react-modal";
import { CloseModalButton } from "../CloseModalButton";

export const ForecastModal = ({ modalIsOpen, closeModal, localWeather }) => {
  // console.log(localWeather.hourly[0].clouds);

  return (
    <Modal isOpen={modalIsOpen}>
      <CloseModalButton closeModal={closeModal} />
      ForecastModal
      <>{}</>
    </Modal>
  );
};
