import clearSkyIcon from "../assets/weather icons/01d.png";
import fewCloudsIcon from "../assets/weather icons/02d.png";
import scatteredCloudsIcon from "../assets/weather icons/03d.png";
import brokenCloudsIcon from "../assets/weather icons/04d.png";
import showerRainIcon from "../assets/weather icons/09d.png";
import rainIcon from "../assets/weather icons/10d.png";
import thunderstormIcon from "../assets/weather icons/11d.png";
import snowIcon from "../assets/weather icons/13d.png";
import mistIcon from "../assets/weather icons/50d.png";

const selectImage = (rawName) => {
  var iconName = rawName.substring(0, 2);
  if (iconName === "01") {
    return clearSkyIcon;
  }
  if (iconName === "02") {
    return fewCloudsIcon;
  }
  if (iconName === "03") {
    return scatteredCloudsIcon;
  }
  if (iconName === "04") {
    return brokenCloudsIcon;
  }
  if (iconName === "09") {
    return showerRainIcon;
  }
  if (iconName === "10") {
    return rainIcon;
  }
  if (iconName === "11") {
    return thunderstormIcon;
  }
  if (iconName === "13") {
    return snowIcon;
  }
  if (iconName === "50") {
    return mistIcon;
  }
};

export default selectImage;
