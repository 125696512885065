import React, { useState } from "react";
import moment from "moment";
import weatherIcon from "../../utils/weatherIcon";
import { ForecastModal } from "./ForecastModal";

export const DailyForecast = ({ localWeather, units }) => {
  const [modalOpen, setModalOpen] = useState(false);

  const openModal = () => {
    setModalOpen(true);
  };

  return (
    <div className="daily-forecast-cont">
      <b className="hourly-title">Daily forecast:</b>
      <div className="weather-forecast">
        {localWeather.daily.slice(0, 6).map((day) => (
          <div
            className="weather-forecast-item tc"
            key={localWeather.daily.indexOf(day)}
            onClick={() => openModal}
          >
            <h3>
              {moment()
                .add(localWeather.daily.indexOf(day), "day")
                .format("dddd")}
            </h3>
            <img
              src={weatherIcon(day.weather[0].icon)}
              className="forecast-icon"
              alt=""
            />
            <br />
            <b>
              {day.weather[0].description.charAt(0).toUpperCase() +
                day.weather[0].description.slice(1)}
            </b>
            <h3 className="forecast-temp">
              {Math.round(day.temp.eve)} °{units === "imperial" ? "F" : "C"}
            </h3>
          </div>
        ))}
        <ForecastModal modalOpen={modalOpen} setModalOpen={setModalOpen} />
      </div>
    </div>
  );
};
