import React from "react";
import moment from "moment";
import weatherIcon from "../../utils/weatherIcon";
// import { ForecastModal } from "./ForecastModal";

export const HourlyForecast = ({ localWeather, units }) => {
  // const [modalIsOpen, setIsOpen] = useState(false);

  // const openModal = () => {
  //   setIsOpen(true);
  // };

  // const closeModal = () => {
  //   setIsOpen(false);
  // };

  return (
    <div className="hourly-forecast-cont">
      <b className="hourly-title">Hourly forecast:</b>
      <div className="hourly-forecast">
        {localWeather.hourly.slice(0, 6).map((hour) => (
          <div
            className={`hourly-forecast-item tc ${
              localWeather.hourly.indexOf(hour) > 2 ? "dontshow" : ""
            }`}
            // onClick={openModal}
            key={localWeather.hourly.indexOf(hour)}
          >
            <h3>
              {moment()
                .add(localWeather.hourly.indexOf(hour), "hour")
                .format("hh:00")}
            </h3>
            <img
              src={weatherIcon(hour.weather[0].icon)}
              className="forecast-icon"
              alt=""
            />
            <b>
              {hour.weather[0].description.charAt(0).toUpperCase() +
                hour.weather[0].description.slice(1)}
            </b>
            <h3 className="forecast-temp">
              {Math.round(hour.temp)} °{units === "imperial" ? "F" : "C"}
            </h3>
          </div>
        ))}
        {/* <ForecastModal
          modalIsOpen={modalIsOpen}
          closeModal={closeModal}
          localWeather={localWeather}
        /> */}
      </div>
    </div>
  );
};
