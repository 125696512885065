import React from "react";
import "../../stylesheets/header.css";
import logo from "../../assets/logo.png";
import { Link } from "wouter";
// import { WeatherWidget } from "./WeatherWidget.js";
// import { ZipCodeSearch } from "./ZipCodeSearch";

export const Header = () => {
  return (
    <div className="header">
      <div className="flex-cont">
        {/* <div className="far-left"> */}
          <Link href="/">
            <img className="logo pointer" src={logo} alt="" />
          </Link>
        {/* </div> */}
        {/* <div className="dead-center">
          <ZipCodeSearch />
        </div> */}
        {/* <div className="far-right"> */}
          {/* <WeatherWidget /> */}
        {/* </div> */}
      </div>
    </div>
  );
};
