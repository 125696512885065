import { useState, useEffect } from "react";
import axios from "axios";
import toast from "react-hot-toast";

const useWeatherLogic = (zipCode, units) => {
  const weatherApiKey = process.env.REACT_APP_WEATHER_API_KEY;
  const locationApiKey = process.env.REACT_APP_LOCATION_API_KEY;

  const [coords, setCoords] = useState({});
  const [localWeather, setLocalWeather] = useState({});
  const [loaded, setLoaded] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [location, setLocation] = useState("");

  const getWeather = () => {
    axios
      .get(
        `https://api.openweathermap.org/data/2.5/onecall?units=${units}&lat=${coords.lat}&lon=${coords.long}&appid=` +
          weatherApiKey
      )
      .then((response) => {
        setLocalWeather(response.data);
        setLoaded(true);
        setDisabled(false);
      });
  };

  useEffect(() => {
    setLoaded(false);
    if (coords.long) {
      getWeather();
      if (zipCode === "") fetchLocation();
    }
    // eslint-disable-next-line
  }, [coords, units]);

  const getCoordsFromBrowser = () => {
    setLoaded(false);
    navigator.geolocation.getCurrentPosition(
      function (position) {
        setCoords({
          long: position.coords.longitude,
          lat: position.coords.latitude,
        });
      },
      function (error) {
        isThereZipCode();
      }
    );
  };

  useEffect(() => {
    getCoordsFromBrowser();
    // eslint-disable-next-line
  }, []);

  const isThereZipCode = () => {
    if (zipCode === "") {
      setDisabled(true);
    } else {
      fetchZipCode();
      setLoaded(false);
    }
  };

  const fetchZipCode = () => {
    setLoaded(false);
    axios
      .get(
        `https://api.positionstack.com/v1/forward?access_key=${locationApiKey}&query=${zipCode}`
      )
      .then((res) => {
        if (res.data.data[0].length === 0) {
          toast.error("Please enter a valid zip code");
          setLoaded(true);
        } else {
          setCoords({
            long: res.data.data[0].longitude,
            lat: res.data.data[0].latitude,
          });
          setLocation(res.data.data[0].label);
        }
      })
      .catch(() => {
        toast.error("Please enter a valid zip code");
        setLoaded(true);
      });
  };

  const fetchLocation = () => {
    axios
      .get(
        `https://api.positionstack.com/v1/reverse?access_key=${locationApiKey}&query=${coords.lat},${coords.long}`
      )
      .then((res) => {
        setLocation(res.data.data[0].label);
      });
  };

  return {
    localWeather,
    loaded,
    disabled,
    isThereZipCode,
    location,
    getCoordsFromBrowser,
    coords,
  };
};

export default useWeatherLogic;
