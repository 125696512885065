import React from "react";
import "../../stylesheets/category-selector.css";
import Select from "react-select";

export const CategorySelector = ({
  categoryOptions,
  category,
  setCategory,
}) => {
  const categoryClassName = (cat) => {
    if (cat.label === category.label) {
      return "category-button category-selected pointer";
    } else {
      return "category-button pointer";
    }
  };

  return (
    <>
      <div className="category-selector">
        {categoryOptions.map((cat) => (
          <div
            className={categoryClassName(cat)}
            onClick={() => setCategory(cat)}
          >
            {cat.label}
          </div>
        ))}
      </div>
      <div className="category-selector-res">
        <Select
          options={categoryOptions}
          getOptionValue={(option) => option.label}
          value={category}
          onChange={(option) => setCategory(option)}
        />
      </div>
    </>
  );
};
