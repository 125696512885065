import React from "react";
import "../stylesheets/navbar.css";
import { Link } from "wouter";

export const NavBar = () => {
  return (
    <div className="navbar">
    <Link href="/news">
      <div className="flex-item pointer darken">News</div>
    </Link>
      <Link href="/weather">
        <div className="flex-item pointer darken">Weather</div>
      </Link>
    </div>
  );
};
