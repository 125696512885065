import React, { useEffect, useState } from "react";
import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Thumbs,
  Controller,
  Autoplay,
} from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "../../stylesheets/newscarrousel.css";

SwiperCore.use([
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Thumbs,
  Controller,
  Autoplay,
]);

export const NewsCarrousel = ({
  article,
  fetchedArticles,
  setFetchedArticles,
  articles,
}) => {

  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  // const [articles, setArticles] = useState([]);

  // const getArticles = () => {
  //   setFetchedArticles(false);
  //   axios
  //     .get(
  //       `https://newsapi.ai/api/v1/article/getArticles?query=%7B%22%24query%22%3A%7B%22categoryUri%22%3A%22dmoz%2F${category.label}%22%7D%2C%22%24filter%22%3A%7B%22forceMaxDataTimeWindow%22%3A%2231%22%2C%22dataType%22%3A%5B%22news%22%5D%7D%7D&resultType=articles&articlesSortBy=rel&articlesCount=50&includeArticleImage=true&articleBodyLen=-1&apiKey=7d10c44a-e910-4c3a-a2f0-d0cf780efe9a`
  //     )
  //     .then((response) => {
  //       console.log(response.data.articles.results);
  //       setArticles(response.data.articles.results);
  //       setFetchedArticles(true);
  //     });
  // };

  const checkArticles = () => {
    // check if every article in articles has an image, if it doesnt pop it out of the array
    for (let i = 0; i < articles.length; i++) {
      if (!articles[i].image) {
        articles.splice(i, 1);
        i--;
      }
    }
  };

  // useEffect(() => {
  //   getArticles();
  //   // eslint-disable-next-line
  // }, [category]);

  // "Sanitize" the articles array, removing any articles without an image source
  useEffect(() => {
    checkArticles();
    // eslint-disable-next-line
  }, [articles]);

  return (
    <>
      {fetchedArticles ? (
        <div className="gallery-container">
          <div className="swiper-container main-gallery">
            {/*Main Carrousel*/}
            <Swiper
              autoplay={{ delay: 5000 }}
              id="main"
              thumbs={{ swiper: thumbsSwiper }}
              modules={[Thumbs]}
              watchSlidesProgress={true}
              wrapperTag="ul"
              navigation
              spaceBetween={0}
              slidesPerView={1}
              onInit={(swiper) => console.log("Swiper Initialized")}
              onSlideChange={(swiper) => console.log("Slide Changed")}
              onReachEnd={(swiper) => console.log("Reach End")}
            >
              {articles.slice(0, 4).map((article) =>
                article.image ? (
                  <SwiperSlide key={article.id} tag="li">
                    <div className="swiper-slide">
                      <a href={article.url} target="_blank" rel="noreferrer">
                        <img
                          width={400}
                          height={300}
                          src={article.image}
                          alt={article.title}
                          className="swiper-img"
                        />
                        <div className="gallery-title">{article.title}</div>
                      </a>
                    </div>
                  </SwiperSlide>
                ) : (
                  <></>
                )
              )}
            </Swiper>
            {/*Thumbs Carrousel*/}
          </div>
          <div className="swiper-container gallery-thumbs">
            <Swiper
              // install Swiper modules
              className="swiper-thumbs"
              initialSlide={0}
              centeredSlides={true}
              centeredSlidesBounds={true}
              spaceBetween={0}
              direction="vertical"
              autoplay={{ delay: 5000 }}
              slidesPerView={4}
              id="thumbs"
              watchOverflow={true}
              onSwiper={setThumbsSwiper}
              watchSlidesProgress={true}
              watchSlidesVisibility={true}
              slideThumbActiveClass="swiper-slide-thumb-active"
            >
              {articles.slice(0, 4).map((article) =>
                article.image ? (
                  <div className="swiper-vertical-slide">
                    <SwiperSlide key={`${article.id}-thumb`} tag="li">
                      <img
                        width={100}
                        height={50}
                        padding={50}
                        src={article.image}
                        alt={article.title}
                        className="swiper-img-thumb"
                      />
                      <div className="thumbs-title overflow-ellipsis">
                        {article.title}
                      </div>
                    </SwiperSlide>
                  </div>
                ) : (
                  <></>
                )
              )}
            </Swiper>
          </div>
        </div>
      ) : (
        // <div>Loading...</div>
        <></>
      )}
    </>
  );
};
