import React, { useState } from "react";
// import { WeatherMap } from "./WeatherMap";
import "../../stylesheets/weather.css";
import { WeatherToday } from "./WeatherToday";
import { DailyForecast } from "./DailyForecast";
import { HourlyForecast } from "./HourlyForecast";
import useWeatherLogic from "../../utils/useWeatherLogic";
import { ZipCodeSearch } from "./ZipCodeSearch";
import { ZipCodeSearchMini } from "./ZipCodeSearchMini";
import { WeatherMap2 } from "./WeatherMap2";

export const Weather = () => {
  const [zipCode, setZipCode] = useState("");
  const [units, setUnits] = useState("imperial");

  let {
    localWeather,
    loaded,
    disabled,
    location,
    isThereZipCode,
    getCoordsFromBrowser,
    coords,
  } = useWeatherLogic(zipCode, units);

  return (
    <div>
      {disabled ? (
        <h3 className="weather-disabled tc">
          <ZipCodeSearchMini
            setZipCode={setZipCode}
            zipCode={zipCode}
            isThereZipCode={isThereZipCode}
            disabled={disabled}
            getCoordsFromBrowser={getCoordsFromBrowser}
            setUnits={setUnits}
            units={units}
          />
          To see the weather in your area enter your zipcode above, or enable
          location on your browser
        </h3>
      ) : loaded ? (
        <>
          <div className="weather">
            <div className="franja-data-flex">
              <h2 className="location">{location}</h2>
              <ZipCodeSearch
                setZipCode={setZipCode}
                zipCode={zipCode}
                isThereZipCode={isThereZipCode}
                disabled={disabled}
                getCoordsFromBrowser={getCoordsFromBrowser}
                setUnits={setUnits}
                units={units}
              />
            </div>
            <div className="weather-big-flex">
              <div className="weather-left">
                <WeatherToday localWeather={localWeather} units={units} />
                <HourlyForecast localWeather={localWeather} units={units} />
              </div>
              <div className="weather-right">
                <WeatherMap2 coords={coords} />
                <DailyForecast localWeather={localWeather} units={units} />
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="weather-loader">
          <div className="loader" />
        </div>
      )}
    </div>
  );
};
