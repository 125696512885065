import React from "react";
import { useEffect, useState } from "react";
import axios from "axios";
import "../../stylesheets/listing.css";
import { NewsCarrousel } from "./NewsCarrousel";
import Cross from "../../assets/icons/cross.png";
import { CategorySelector } from "./CategorySelector";

export const ArticleListing = ({ article }) => {
  const NewsApiKey = process.env.REACT_APP_NEWS_API_KEY;
  const [fetchedArticles, setFetchedArticles] = useState(false);

  const categoryOptions = [
    // TODO: find out why the hell there's two health, sports, business, science categories that bring back different results
    { uri: "news/Health", label: "Health", parentUri: "news" },
    { uri: "dmoz/Arts", label: "Arts", parentUri: "dmoz" },
    { uri: "news/Environment", label: "Environment", parentUri: "news" },
    { uri: "news/Science", label: "Science", parentUri: "news" },
    { uri: "news/Politics", label: "Politics", parentUri: "news" },
    { uri: "news/Business", label: "Business", parentUri: "news" },
    {
      uri: "news/Arts_and_Entertainment",
      label: "Arts and Entertainment",
      parentUri: "news",
    },
    { uri: "news/Sports", label: "Sports", parentUri: "news" },
    { uri: "dmoz/Computers", label: "Computers", parentUri: "dmoz" },
    { uri: "dmoz/Games", label: "Games", parentUri: "dmoz" },
    { uri: "dmoz/Home", label: "Home", parentUri: "dmoz" },
    { uri: "dmoz/Recreation", label: "Recreation", parentUri: "dmoz" },
    { uri: "dmoz/Shopping", label: "Shopping", parentUri: "dmoz" },
    { uri: "dmoz/Society", label: "Society", parentUri: "dmoz" },
  ];

  const [category, setCategory] = useState(categoryOptions[0]);

  const getArticles = () => {
    //TODO: Fetch only articles in english
    setFetchedArticles(false);
    axios
      .post(
        `https://newsapi.ai/api/v1/article/getArticles?apiKey=${NewsApiKey}`,
        {
          action: "getArticles",
          categoryUri: category.uri,
          lang: "eng",
          articlesPage: 1,
          articlesCount: 29,
          articlesSortBy: "date",
          articlesSortByAsc: false,
          articlesArticleBodyLen: -1,
          resultType: "articles",
          dataType: ["news", "pr"],
          forceMaxDataTimeWindow: 31,
          apiKey: NewsApiKey,
        }
      )
      .then((response) => {
        setArticles(response.data.articles.results);
        setFetchedArticles(true);
      });
  };

  const [articles, setArticles] = useState([]);

  useEffect(() => {
    getArticles();
    // eslint-disable-next-line
  }, [category]);

  return (
    <div style={{ padding: "1rem" }}>
      <CategorySelector
        categoryOptions={categoryOptions}
        category={category}
        setCategory={setCategory}
      />
      {fetchedArticles ? (
        <div>
          <h1 className="latest-news-title">Latest {category.label} news</h1>
          <br />
          <div className="carrousel-display">
            <NewsCarrousel
              articles={articles}
              fetchedArticles={fetchedArticles}
              setFetchedArticles={setFetchedArticles}
            />
          </div>
          <div className="listing-cont">
            {articles.slice(5, articles.length).map((article) =>
              article.image ? (
                <a href={article.url} className="nolink" target="_blank">
                  <div className="article pointer grow" key={article.uri}>
                    <img
                      alt=""
                      src={article.image}
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = Cross; // TODO: replace with proper placeholder image
                      }}
                      className="article-img"
                    />
                    <div key={article.id} className="article-title">
                      <font color="black">{article.title}</font>
                    </div>
                  </div>
                </a>
              ) : (
                <></>
              )
            )}
          </div>
        </div>
      ) : (
        <div className="home-loader"></div>
      )}
    </div>
  );
};
