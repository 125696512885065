import React from "react";
import crossIcon from "../assets/icons/cross.png";
import "../stylesheets/modal.css";

export const CloseModalButton = ({ closeModal }) => {
  return (
    <div onClick={closeModal}>
      <img alt="X" className="close-modal-icon pointer" src={crossIcon} />
    </div>
  );
};
