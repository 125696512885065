import React from "react";
import weatherIcon from "../../utils/weatherIcon";

export const WeatherToday = ({ localWeather, units }) => {
  console.log(localWeather);

  return (
    <div className="weather-today">
      <h2 className="tc">Weather now:</h2>
      <br />
      <div className="weather-today-thingy">
        <img
          src={weatherIcon(localWeather.current.weather[0].icon)}
          className="today-icon"
          alt=""
        />
        <h1 className="today-temp">
          {localWeather.current.temp.toFixed(1)} °
          {units === "imperial" ? "F" : "C"}
        </h1>
        {/* {localWeather.current.weather[0].description.charAt(0).toUpperCase() +
        localWeather.current.weather[0].description.slice(1)} */}
      </div>
      <div className="today-info">
        <div>
          <h3>Feels like: </h3>
          <h1>{localWeather.current.feels_like} °K</h1>
          <br />
          <h3>Pressure: </h3>
          <h1>{localWeather.current.pressure} hPa</h1>
        </div>
        <div className="tr">
          <h3>Humidity: </h3>
          <h1>{localWeather.current.humidity}%</h1>
          <br />
          <h3>Description: </h3>
          <h1>
            {localWeather.current.weather[0].description
              .charAt(0)
              .toUpperCase() +
              localWeather.current.weather[0].description.slice(1)}
          </h1>
        </div>
      </div>
      {/* <div className="modal-flex">
        <div>
          <h3>Temperature: </h3>
          <h1>{localWeather.current.temp} °K</h1>
          <br />
          <br />
        </div>
        <div>
          <br />
          <h3>Wind speed: </h3>
          <h1>{localWeather.current.wind_speed} mph</h1>
          <br />
        </div>
      </div> */}
    </div>
  );
};
