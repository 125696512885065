import React, { useEffect, useState } from "react";
import "../../stylesheets/weather-map.css";
import WindowDimensions from "../../utils/screenSize";

export const WeatherMap2 = ({ coords }) => {
  let { lat, long } = coords;
  let srcString = `https://embed.windy.com/embed2.html?lat=${lat}&lon=${long}7&zoom=5&level=surface&overlay=temp&product=ecmwf&menu=&message=&marker=&calendar=now&pressure=&type=map&location=coordinates&detail=&metricWind=default&metricTemp=%C2%B0F&radarRange=-1`;
  const { width } = WindowDimensions();
  const [mapComponent, setMapComponent] = useState(
    <iframe
      title="weather-map"
      width={width > 1000 ? "950" : width - 20}
      height="300"
      src={srcString}
      frameborder="0"
    ></iframe>
  );

  useEffect(() => {
    setMapComponent(
      <iframe
        title="weather-map"
        width={width > 1000 ? "950" : width - 35}
        height="300"
        src={srcString}
        frameborder="0"
      ></iframe>
    );
    // eslint-disable-next-line
  }, [width]);

  return <div className="weather-map-container">{mapComponent}</div>;
};
