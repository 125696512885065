import React from "react";
import { ArticleListing } from "./ArticleListing";

export const Home = () => {

  return (
    <div>
      <ArticleListing />
    </div>
  );
};
