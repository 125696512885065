import React from "react";
import "./stylesheets/app.css";
import { Header } from "./components/header/Header";
import { NavBar } from "./components/NavBar";
import { RoutesContainer } from "./components/RoutesContainer";
import "./stylesheets/loader.css";
import "./stylesheets/utils.css";
import "./stylesheets/responsive.css";
import { Toaster } from "react-hot-toast";

function App() {
  // GEOLOCATION STUFF THAT THE HEADER AND WEATHER COMPONENT USE

  return (
    <div>
      <Header />
      <NavBar />
      <RoutesContainer />
      {/* <Footer /> */}
      <Toaster />
    </div>
  );
}

export default App;
