import React from "react";
import { Route } from "wouter";
import { Home } from "./home/Home.js";
import { Weather } from "./weather/Weather.js";

export const RoutesContainer = () => {
  return (
    <div>
      <Route path="/">
        <Home />
      </Route>
      <Route path="/news">
        <Home />
      </Route>
      <Route path="/weather">
        <Weather />
      </Route>
    </div>
  );
};
